import $ from 'jquery'
import 'slick-carousel'
// import * as THREE from './three.js'

// import { CanvasRenderer } from 'three-canvas-renderer'
// import stars2 from './textures/stars2.jpg'
// import aomap_retake_bg from './textures/aomap_retake_bg.png'
// import aomap_retake from './textures/aomap_retake.png'
// import texture0 from './textures/stars2.jpg'
// import OrbitControls from 'three-orbitcontrols'
$(document).ready(function () {
  dhsv_vc_virtual_room()
})

function dhsv_vc_virtual_room() {
  $('.dhsv_vc_virtual_room').each(function () {
    $('.slider_wrapper').slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      // responsive: [
      //   {
      //     breakpoint: 991,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 3,
      //     },
      //   },
      //   {
      //     breakpoint: 767,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 2,
      //     },
      //   },
      //   {
      //     breakpoint: 575,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //     },
      //   },
      // ],
    })
    //   window.THREE = THREE
    //   var mesh0, mesh1, mesh2, mesh3, mesh4
    //   var fps = 120
    //   var meshes = []
    //   var intersectedMesh
    //   var raycaster
    //   var rayMouse = { x: 0, y: 0 }
    //   var ambientLight, light, kickerLight
    //   var renderer, scene, camera
    //   // var contentLoaded = false
    //   // var allowRot = false
    //   // var clickPositionX, clickPositionY
    //   // var touchPositionX, touchPositionY
    //   // var currentMeshRotationHorizontal = 0,
    //   //   currentMeshRotationVertical = 0
    //   // var rotValClickPositionX, rotValClickPositionY
    //   // var rotValTouchPositionX, rotValTouchPositionY
    //   // var scrolledToView = true
    //   var group, env
    //   // var loadCounter = 0
    //   // var colorBase = new THREE.Color(0xdddddd)
    //   var colorHolder = new THREE.Color(0xdddddd)
    //   // var colorActive = new THREE.Color(0x0b2474)
    //   var mapStarDiffuse = new THREE.TextureLoader().load(stars2)
    //   var mapRoomBgDiffuse = new THREE.TextureLoader().load(aomap_retake_bg)
    //   var mapRoomDiffuse = new THREE.TextureLoader().load(aomap_retake)
    //   var mouseOverMesh = false
    //   var intersectLogged
    //   var meshCounter = 1
    //   // var lastMeshCounter = false
    //   var lastLogged = null
    //   // var pointOnMesh
    //   var intersects = null
    //   // var meshFocused = false
    //   // var zoomedIn = false
    //   // var canvasRendering = false
    //   // var cameraPos =  new THREE.Vector3( 0, 0, 0 );
    //   var startTourAtValue = 6
    //   // var changedStartColor = false;
    //
    //   var controls
    //   var canvas = document.getElementById('canvas')
    //
    //   // function webglAvailable() {
    //   //   try {
    //   //     var canvas = document.getElementById('canvas')
    //   //     console.log(
    //   //       window.WebGLRenderingContext &&
    //   //         (canvas.getContext('webgl') ||
    //   //           canvas.getContext('experimental-webgl'))
    //   //     ) //eslint-disable-line
    //   //     return !!(
    //   //       window.WebGLRenderingContext &&
    //   //       (canvas.getContext('webgl') ||
    //   //         canvas.getContext('experimental-webgl'))
    //   //     )
    //   //   } catch (e) {
    //   //     return false
    //   //   }
    //   // }
    //   //this is the renderer
    //   // if (true) {
    //   renderer = new THREE.WebGLRenderer({
    //     canvas: canvas,
    //     alpha: true,
    //     antialias: true,
    //   })
    //   console.log(renderer) //eslint-disable-line
    //   renderer.setClearColor(0xeeeeee, 0)
    //   renderer.setPixelRatio(window.devicePixelRatio)
    //   renderer.setSize(window.innerWidth, window.innerHeight)
    //   // }
    //   // else {
    //   //   renderer = new CanvasRenderer({
    //   //     canvas: document.getElementById('canvas'),
    //   //     alpha: true,
    //   //     antialias: true,
    //   //   })
    //   //   renderer.setClearColor(0x000000, 0)
    //   //   renderer.setPixelRatio(window.devicePixelRatio)
    //   //   renderer.setSize(window.innerWidth, window.innerHeight)
    //   //
    //   //   // canvasRendering = true
    //   //
    //   //   console.log('webGL not supported!')
    //   // }
    //
    //   init()
    //
    //   function init() {
    //     document.getElementById('canvas').addEventListener('mousemove', mouseMove)
    //     // document.getElementById("canvas").addEventListener("touchmove", controlRotTouch);
    //     document.getElementById('canvas').addEventListener('mousedown', enableRot)
    //     document
    //       .getElementById('canvas')
    //       .addEventListener('touchstart', enableRot)
    //     // document.getElementById("canvas").addEventListener("mouseup", disableRotation);
    //     // document.getElementById("canvas").addEventListener("touchend", disableRotation);
    //
    //     raycaster = new THREE.Raycaster()
    //     group = new THREE.Group()
    //     env = new THREE.Group()
    //     scene = new THREE.Scene()
    //
    //     // scene.background = cubeTexture;
    //
    //     //this is the camera
    //     camera = new THREE.PerspectiveCamera(
    //       80,
    //       window.innerWidth / window.innerHeight,
    //       0.01,
    //       10000
    //     )
    //     // camera.position.set(0, -100, 13.5);
    //
    //     camera.position.set(0, 10, 0)
    //     // camera.lookAt(new THREE.Vector3(10,3,0));
    //
    //     controls = new OrbitControls(camera, renderer.domElement)
    //     controls.rotateSpeed = -0.5
    //
    //     controls.minDistance = 5
    //     controls.maxDistance = 5
    //     controls.minPolarAngle = 1
    //     controls.maxPolarAngle = Math.PI / 2
    //     controls.minAzimuthAngle = -1.6 // radians
    //     controls.maxAzimuthAngle = -1.3 // radians
    //
    //     //these are the lights
    //     ambientLight = new THREE.AmbientLight(0xffffff, 1, 0, 1)
    //     scene.add(ambientLight)
    //
    //     light = new THREE.PointLight(0xffffff, 0.2)
    //     light.position.set(-1500, 10, 100)
    //
    //     scene.add(light)
    //
    //     kickerLight = new THREE.PointLight(0xdfe3ee, 0.9, 0, 2)
    //     kickerLight.position.set(100, -5, -60)
    //     scene.add(kickerLight)
    //
    //     new THREE.JSONLoader().load('models/room_retake_bg.json', function (
    //       geometry
    //     ) {
    //       var material = new THREE.MeshStandardMaterial({
    //         map: mapRoomBgDiffuse,
    //         color: 0xffffff,
    //       })
    //
    //       mesh0 = new THREE.Mesh(geometry, material)
    //       mesh0.frustumCulled = false
    //       mesh0.rotation.set(0, 0, 0)
    //       mesh0.name = '0'
    //       meshes[0] = mesh0
    //       env.add(mesh0)
    //       // loadCounter++
    //     })
    //
    //     new THREE.JSONLoader().load('models/room_retake.json', function (
    //       geometry
    //     ) {
    //       var material = new THREE.MeshStandardMaterial({
    //         map: mapRoomDiffuse,
    //         color: 0xffffff,
    //       })
    //
    //       mesh0 = new THREE.Mesh(geometry, material)
    //       mesh0.frustumCulled = false
    //       mesh0.rotation.set(0, 0, 0)
    //       mesh0.name = '0'
    //       meshes[0] = mesh0
    //       env.add(mesh0)
    //       // loadCounter++
    //     })
    //
    //     new THREE.JSONLoader().load('models/stars.json', function (geometry) {
    //       var material = new THREE.MeshStandardMaterial({
    //         map: mapStarDiffuse,
    //         color: 0xffffff,
    //       })
    //
    //       mesh0 = new THREE.Mesh(geometry, material)
    //       mesh0.frustumCulled = false
    //       mesh0.rotation.set(0, 0, 0)
    //       mesh0.name = '0'
    //       meshes[0] = mesh0
    //       env.add(mesh0)
    //       // loadCounter++
    //     })
    //
    //     // new THREE.JSONLoader().load("models/sign0.json", function (geometry){
    //     //
    //     //     var material = new THREE.MeshStandardMaterial({map: mapDiffuse, color : 0xffffff, metalness : 0, roughness: 0.4});
    //     //
    //     //     mesh6 = new THREE.Mesh( geometry, material );
    //     //     mesh6.frustumCulled = false;
    //     //     mesh6.rotation.set(0,0,0);
    //     //     mesh6.name = "6";
    //     //     meshes[6] = mesh6;
    //     //     env.add(mesh6);
    //     //     loadCounter++;
    //     // });
    //
    //     // new THREE.JSONLoader().load("models/sign1.json", function (geometry){
    //     //     var material = new THREE.MeshStandardMaterial({map: mapDiffuse, color : 0xffffff, metalness : 0, roughness: 0.4});
    //     //
    //     //     mesh7 = new THREE.Mesh( geometry, material );
    //     //     mesh7.frustumCulled = false;
    //     //     mesh7.rotation.set(0,0,0);
    //     //     mesh7.name = "7";
    //     //     meshes[7] = mesh7;
    //     //     env.add(mesh7);
    //     //     loadCounter++;
    //     // });
    //
    //     // new THREE.JSONLoader().load("models/sign2.json", function (geometry){
    //     //
    //     //     var material = new THREE.MeshStandardMaterial({map: mapDiffuse, color : 0xffffff, metalness : 0, roughness: 0.4});
    //     //
    //     //     mesh8 = new THREE.Mesh( geometry, material );
    //     //     mesh8.frustumCulled = false;
    //     //     mesh8.rotation.set(0,0,0);
    //     //     mesh8.name = "8";
    //     //     meshes[8] = mesh8;
    //     //     env.add(mesh8);
    //     //     loadCounter++;
    //     // });
    //
    //     new THREE.JSONLoader().load('models/tv_retake.json', function (geometry) {
    //       var material = new THREE.MeshStandardMaterial({
    //         color: 0xff6162,
    //         metalness: 0,
    //         roughness: 0.3,
    //       })
    //
    //       mesh1 = new THREE.Mesh(geometry, material)
    //       mesh1.frustumCulled = false
    //       mesh1.rotation.set(0, 0, 0)
    //       mesh1.name = '1'
    //       mesh1.material.originalColor = 0xff6162
    //       meshes[1] = mesh1
    //       group.add(mesh1)
    //       // loadCounter++
    //     })
    //
    //     new THREE.JSONLoader().load('models/sign_retake_0.json', function (
    //       geometry
    //     ) {
    //       var material = new THREE.MeshStandardMaterial({
    //         color: 0xff6162,
    //         metalness: 0,
    //         roughness: 0.3,
    //       })
    //
    //       mesh2 = new THREE.Mesh(geometry, material)
    //       mesh2.frustumCulled = false
    //       mesh2.rotation.set(0, 0, 0)
    //       mesh2.name = '2'
    //       mesh2.material.originalColor = 0xff6162
    //       meshes[2] = mesh2
    //       group.add(mesh2)
    //       // loadCounter++
    //     })
    //
    //     new THREE.JSONLoader().load('models/sign_retake_1.json', function (
    //       geometry
    //     ) {
    //       var material = new THREE.MeshStandardMaterial({
    //         color: 0xff6162,
    //         metalness: 0,
    //         roughness: 0.3,
    //       })
    //
    //       mesh3 = new THREE.Mesh(geometry, material)
    //       mesh3.frustumCulled = false
    //       mesh3.rotation.set(0, 0, 0)
    //       mesh3.name = '3'
    //       mesh3.material.originalColor = 0xff6162
    //       meshes[3] = mesh3
    //       group.add(mesh3)
    //       // loadCounter++
    //     })
    //
    //     new THREE.JSONLoader().load('models/sign_retake_2.json', function (
    //       geometry
    //     ) {
    //       var material = new THREE.MeshStandardMaterial({
    //         color: 0xff6162,
    //         metalness: 0,
    //         roughness: 0.3,
    //       })
    //
    //       mesh4 = new THREE.Mesh(geometry, material)
    //       mesh4.frustumCulled = false
    //       mesh4.rotation.set(0, 0, 0)
    //       mesh4.name = '4'
    //       mesh4.material.originalColor = 0xff6162
    //       meshes[4] = mesh4
    //       group.add(mesh4)
    //       // loadCounter++
    //     })
    //
    //     // new THREE.JSONLoader().load("models/poster0.json", function (geometry){
    //     //
    //     //     var material = new THREE.MeshStandardMaterial({color : 0xade4f9, metalness : 0, roughness: 0.3});
    //     //
    //     //     mesh2 = new THREE.Mesh( geometry, material );
    //     //     mesh2.frustumCulled = false;
    //     //     mesh2.rotation.set(0,0,0);
    //     //     mesh2.name = "2";
    //     //     meshes[2] = mesh2;
    //     //     group.add(mesh2);
    //     //     loadCounter++;
    //     // });
    //
    //     // new THREE.JSONLoader().load("models/poster1.json", function (geometry){
    //     //
    //     //     var material = new THREE.MeshStandardMaterial({color : 0xade4f9, metalness : 0, roughness: 0.3});
    //     //
    //     //     mesh3 = new THREE.Mesh( geometry, material );
    //     //     mesh3.frustumCulled = false;
    //     //     mesh3.rotation.set(0,0,0);
    //     //     mesh3.name = "3";
    //     //     meshes[3] = mesh3;
    //     //     group.add(mesh3);
    //     //     loadCounter++;
    //     // });
    //
    //     // new THREE.JSONLoader().load("models/poster2.json", function (geometry){
    //     //
    //     //     var material = new THREE.MeshStandardMaterial({color : 0xade4f9, metalness : 0, roughness: 0.3});
    //     //
    //     //     mesh4 = new THREE.Mesh( geometry, material );
    //     //     mesh4.frustumCulled = false;
    //     //     mesh4.rotation.set(0,0,0);
    //     //     mesh4.name = "4";
    //     //     meshes[4] = mesh4;
    //     //     group.add(mesh4);
    //     //     loadCounter++;
    //     // });
    //     //
    //     // new THREE.JSONLoader().load("models/poster3.json", function (geometry){
    //     //
    //     //     var material = new THREE.MeshStandardMaterial({color : 0xade4f9, metalness : 0, roughness: 0.3});
    //     //
    //     //     mesh5 = new THREE.Mesh( geometry, material );
    //     //     mesh5.frustumCulled = false;
    //     //     mesh5.rotation.set(0,0,0);
    //     //     mesh5.name = "5";
    //     //     meshes[5] = mesh5;
    //     //     group.add(mesh5);
    //     //     loadCounter++;
    //     // });
    //
    //     // if(canvasRendering === false) {
    //     //
    //     //     new THREE.JSONLoader().load("models/ground.json", function (geometry) {
    //     //
    //     //         mapDiffuse = new THREE.TextureLoader().load("models/Telehouse_Textur.jpg");
    //     //
    //     //         var materialGround = new THREE.MeshBasicMaterial({map: mapDiffuse});
    //     //
    //     //         mesh12 = new THREE.Mesh(geometry, materialGround);
    //     //         mesh12.frustumCulled = false;
    //     //         mesh12.rotation.set(0, 0, 0);
    //     //         mesh12.name = "11";
    //     //         env.add(mesh12);
    //     //         loadCounter++;
    //     //     });
    //     // }else{
    //     //     loadCounter++;
    //     // }
    //
    //     group.position.set(0, -2, 0)
    //     group.rotation.x = 0
    //     group.rotation.y = -2
    //
    //     env.position.set(0, -2, 0)
    //     env.rotation.x = 0
    //     env.rotation.y = -2
    //
    //     scene.add(group)
    //     scene.add(env)
    //
    //     prepareStartScene()
    //   }
    //
    //   function prepareStartScene() {
    //     meshCounter = startTourAtValue
    //     lastLogged = startTourAtValue.toString()
    //   }
    //
    //   function enableRot() {
    //     // allowRot = true
    //     console.log(group.rotation.y) //eslint-disable-line
    //
    //     // clickPositionX = 0
    //     // clickPositionY = 0
    //     // touchPositionX = 0
    //     // touchPositionY = 0
    //     //
    //     // rotValClickPositionX = event.clientX
    //     // rotValClickPositionY = event.clientY
    //     // rotValTouchPositionX = event.clientX
    //     // rotValTouchPositionY = event.clientY
    //     // currentMeshRotationVertical = group.rotation.x
    //     // currentMeshRotationHorizontal = group.rotation.y
    //
    //     if (mouseOverMesh === true) {
    //       meshClicked()
    //     }
    //   }
    //
    //   // function disableRotation(){
    //   //     allowRot = false;
    //   //     wantedRot = window.pageYOffset / 200;
    //   //     rotValX = 0;
    //   //     rotValY = 0;
    //   // }
    //
    //   // function controlRotTouch(event){
    //   //     scrolledToView = true;
    //   //
    //   //     console.log(group.rotation.y)
    //   //
    //   //     if(contentLoaded === true && allowRot === true){
    //   //         dragged = true;
    //   //
    //   //         if (touchPositionX < event.touches[0].clientX){
    //   //             rotValX =  event.touches[0].clientX - rotValTouchPositionX;
    //   //             group.rotation.y = currentMeshRotationHorizontal + (rotValX * 0.005);
    //   //             env.rotation.y = currentMeshRotationHorizontal + (rotValX * 0.005);
    //   //             currentMeshRotationHorizontal = group.rotation.y;
    //   //             currentMeshRotationHorizontal = env.rotation.y;
    //   //             rotValTouchPositionX = event.touches[0].clientX;
    //   //             handRotY = group.rotation.y;
    //   //             handRotY = env.rotation.y;
    //   //         } if (touchPositionX > event.touches[0].clientX){
    //   //             rotValX = rotValTouchPositionX - event.touches[0].clientX;
    //   //             group.rotation.y = currentMeshRotationHorizontal - (rotValX * 0.005);
    //   //             env.rotation.y = currentMeshRotationHorizontal - (rotValX * 0.005);
    //   //             currentMeshRotationHorizontal = group.rotation.y;
    //   //             currentMeshRotationHorizontal = env.rotation.y;
    //   //             rotValTouchPositionX = event.touches[0].clientX;
    //   //             handRotY = group.rotation.y;
    //   //             handRotY = env.rotation.y;
    //   //         } if (touchPositionY < event.touches[0].clientY){
    //   //             rotValY = event.touches[0].clientY - rotValTouchPositionY;
    //   //             group.rotation.x = currentMeshRotationVertical + (rotValY * 0.0005);
    //   //             env.rotation.x = currentMeshRotationVertical + (rotValY * 0.0005);
    //   //             currentMeshRotationVertical = group.rotation.x;
    //   //             currentMeshRotationVertical = env.rotation.x;
    //   //             rotValTouchPositionY = event.touches[0].clientY;
    //   //             handRotY = group.rotation.y;
    //   //             handRotY = env.rotation.y;
    //   //         } if (touchPositionY > event.touches[0].clientY){
    //   //             rotValY = rotValTouchPositionY - event.touches[0].clientY;
    //   //             rotation.x = currentMeshRotationVertical - (rotValY * 0.0005);
    //   //             currentMeshRotationVertical = group.rotation.x;
    //   //             currentMeshRotationVertical = env.rotation.x;
    //   //             rotValTouchPositionY = event.touches[0].clientY;
    //   //             handRotY = group.rotation.y;
    //   //             handRotY = env.rotation.y;
    //   //         }
    //   //         touchPositionX = event.touches[0].clientX;
    //   //         touchPositionY = event.touches[0].clientY;
    //   //     }
    //   // }
    //
    //   function mouseMove(event) {
    //     rayMouse.x =
    //       ((event.clientX - renderer.domElement.offsetLeft) /
    //         renderer.domElement.clientWidth) *
    //         2 -
    //       1
    //     rayMouse.y =
    //       -(
    //         (event.clientY - renderer.domElement.offsetTop) /
    //         renderer.domElement.clientHeight
    //       ) *
    //         2 +
    //       1
    //
    //     raycaster.setFromCamera(rayMouse, camera)
    //
    //     intersects = raycaster.intersectObjects(group.children)
    //
    //     if (intersects.length > 0) {
    //       // pointOnMesh = intersects[0].point
    //
    //       document.getElementById('canvas').style.cursor = 'pointer'
    //
    //       mouseOverMesh = true
    //
    //       intersectLogged = intersects[0].object.name
    //
    //       if (intersects[0].object !== intersectedMesh) {
    //         intersectedMesh = intersects[0].object
    //
    //         intersectedMesh.currentHex = intersectedMesh.material.color.getHex()
    //       }
    //     } else {
    //       intersectedMesh = null
    //       intersectLogged = null
    //
    //       document.getElementById('canvas').style.cursor = 'default'
    //     }
    //
    //     // scrolledToView = true
    //
    //     // if ( contentLoaded === true && allowRot === true ) {
    //     //
    //     //     dragged = true;
    //     //
    //     //     stopRotation = true;
    //     //
    //     //     if (clickPositionX < event.clientX){
    //     //         rotValX =  event.clientX - rotValClickPositionX;
    //     //         group.rotation.y = currentMeshRotationHorizontal + (rotValX * 0.005);
    //     //         env.rotation.y = currentMeshRotationHorizontal + (rotValX * 0.005);
    //     //         currentMeshRotationHorizontal = group.rotation.y;
    //     //         rotValClickPositionX = event.clientX;
    //     //         handRotY = group.rotation.y;
    //     //         handRotY = env.rotation.y;
    //     //     } if (clickPositionX > event.clientX){
    //     //         rotValX = rotValClickPositionX - event.clientX;
    //     //         group.rotation.y = currentMeshRotationHorizontal - (rotValX * 0.005);
    //     //         env.rotation.y = currentMeshRotationHorizontal - (rotValX * 0.005);
    //     //         currentMeshRotationHorizontal = group.rotation.y;
    //     //         rotValClickPositionX = event.clientX;
    //     //         handRotY = group.rotation.y;
    //     //         handRotY = env.rotation.y;
    //     //     }
    //     //     // if (clickPositionY < event.clientY){
    //     //     //     rotValY = event.clientY - rotValClickPositionY;
    //     //     //     if(rotValY > 0) {
    //     //     //         group.rotation.x = currentMeshRotationVertical + (rotValY * 0.0005);
    //     //     //         env.rotation.x = currentMeshRotationVertical + (rotValY * 0.0005);
    //     //     //     }
    //     //     //     currentMeshRotationVertical = group.rotation.x;
    //     //     //     rotValClickPositionY = event.clientY;
    //     //     //     handRotY = group.rotation.y;
    //     //     //     handRotY = env.rotation.y;
    //     //     // }
    //     //     // if (clickPositionY > event.clientY){
    //     //     //     rotValY = rotValClickPositionY - event.clientY;
    //     //     //     if(rotValY > 0) {
    //     //     //         group.rotation.x = currentMeshRotationVertical - (rotValY * 0.0005);
    //     //     //         env.rotation.x = currentMeshRotationVertical - (rotValY * 0.0005);
    //     //     //     }
    //     //     //     currentMeshRotationVertical = group.rotation.x;
    //     //     //     rotValClickPositionY = event.clientY;
    //     //     //     handRotY = group.rotation.y;
    //     //     //     handRotY = env.rotation.y;
    //     //     // }
    //     //     clickPositionX = event.clientX;
    //     //     clickPositionY = event.clientY;
    //     // }
    //   }
    //
    //   window.onresize = function () {
    //     camera.aspect = window.innerWidth / window.innerHeight
    //     camera.updateProjectionMatrix()
    //
    //     renderer.setSize(window.innerWidth, window.innerHeight)
    //   }
    //
    //   function meshClicked() {
    //     if (intersectLogged !== null && intersectLogged !== lastLogged) {
    //       meshCounter = parseInt(intersectLogged)
    //
    //       lastLogged = intersectLogged
    //
    //       // /** reset color of last mesh */
    //       //
    //       // if (lastMeshCounter !== false) {
    //       //   const newColor = colorHolder
    //       //
    //       //   TweenMax.to(meshes[lastMeshCounter].material.color, 0.2, {
    //       //     ease: Power3.easeOut,
    //       //     r: newColor.r,
    //       //     g: newColor.g,
    //       //     b: newColor.b,
    //       //   })
    //       // }
    //
    //       colorHolder = meshes[meshCounter].material.color
    //       console.log(colorHolder) //eslint-disable-line
    //
    //       // /** change color of mesh */
    //       // TweenMax.to(meshes[meshCounter].material.color, 0.5, {
    //       //   ease: Power3.easeOut,
    //       //   r: colorActive.r,
    //       //   g: colorActive.g,
    //       //   b: colorActive.b,
    //       // })
    //       //
    //       // lastMeshCounter = meshCounter
    //
    //       // /** raise mesh */
    //       // TweenMax.to(meshes[meshCounter].position, 0.5,{
    //       //     ease: Power3.easeOut,
    //       //     y:0.15
    //       // });
    //
    //       // /** reset position of last mesh*/
    //       // TweenMax.to(meshes[lastMeshCounter].position, 0.5,{
    //       //     ease: Power3.easeOut,
    //       //     y:0
    //       // });
    //
    //       /** zoom camera on clicked mesh */
    //       // TweenMax.to(camera.position,1.5,{
    //       //     ease: Power3.easeOut,
    //       //     x:(pointOnMesh.x + cameraPos.x)/2,
    //       //     // y:(pointOnMesh.y + cameraPos.y)/2,
    //       //     z:(pointOnMesh.z + cameraPos.z)/2,
    //       //     onComplete: zoomedInToTrue
    //       // });
    //
    //       // stopRotationTimeout();
    //     } else {
    //       if (intersectLogged !== lastLogged) {
    //         console.log(foo) //eslint-disable-line
    //       }
    //     }
    //   }
    //
    //   // function zoomedInToTrue() {
    //   //   zoomedIn = true
    //   // }
    //
    //   // var timeCounter = 0
    //
    //   function renderScene() {
    //     // timeCounter++
    //
    //     // if (loadCounter === 7) {
    //     //   contentLoaded = true
    //     // }
    //
    //     // if(contentLoaded === true && changedStartColor === false){
    //     //
    //     //     TweenMax.to(meshes[startTourAtValue].material.color, 0.5,{
    //     //         ease: Power3.easeOut,
    //     //         r: colorActive.r,
    //     //         g: colorActive.g,
    //     //         b: colorActive.b
    //     //     });
    //     //     changedStartColor = true;
    //     // }
    //
    //     // if(stopRotation === false) {
    //     //
    //     //     group.rotation.y += rotationSpeed;
    //     //     helperGroup.rotation.y += rotationSpeed;
    //     //     env.rotation.y += rotationSpeed;
    //     // }
    //
    //     camera.updateProjectionMatrix()
    //     renderer.render(scene, camera)
    //   }
    //
    //   animate(camera, renderScene, fps)
  })
}
//
// function animate(camera, renderScene, fps) {
//   setTimeout(function () {
//     camera.updateProjectionMatrix()
//     requestAnimationFrame(animate)
//   }, 1000 / fps)
//
//   renderScene()
// }
